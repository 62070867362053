// extracted by mini-css-extract-plugin
export var container = "beer-gallery-module--container--GqTfa";
export var description = "beer-gallery-module--description--C-mcj";
export var descriptionContainer = "beer-gallery-module--description-container--QlVMH";
export var descriptionLeft = "beer-gallery-module--description-left--xSfYb";
export var frame = "beer-gallery-module--frame--aQwfr";
export var frameRight = "beer-gallery-module--frame-right--nYyg5";
export var header = "beer-gallery-module--header--O3X1o";
export var neon = "beer-gallery-module--neon--ISIkY";
export var neonLeft = "beer-gallery-module--neon-left--G+llH";
export var neonRight = "beer-gallery-module--neon-right--L+FLP";
export var shadow = "beer-gallery-module--shadow--ype7r";
export var shadowContainer = "beer-gallery-module--shadow-container--OzBFF";
export var shadowText = "beer-gallery-module--shadow-text--9WGey";
export var text = "beer-gallery-module--text--9OrVw";