import React from "react"
import * as styles from "./central-header.module.scss"
import { Container, Row, Col } from "react-bootstrap";

export default function CentralHeaderRow(props) {
  return <Container>
    <Row>
      <Col xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
        <div className={styles.container}>
          <div className={styles.header}>{props.header}</div>
          <div className={styles.subheader}>{props.subheader}</div>
        </div>
      </Col>
    </Row>
  </Container>




}
