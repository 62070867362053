import React from "react";
import Layout from "../components/layout/layout";
import WeAreSection from "../page-sections/we-are-section/we-are-section";
import LandingSection from "../page-sections/landing-section/landing-section";
import GallerySection from "../page-sections/gallery-section/gallery-section";
import CounterSection from "../page-sections/counter-section/counter-section";
import BeersSection from "../page-sections/beers-section/beers-section";
import ThirstySection from "../page-sections/thirsty-section/thirsty-section";

export default function Home() {
  return <>
    <Layout>
      <LandingSection />
      <WeAreSection />
      {/* <CounterSection /> */}
      <BeersSection />
      <ThirstySection/>
      <GallerySection />
    </Layout>
  </>
}
