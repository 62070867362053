import {graphql, Link, useStaticQuery} from "gatsby";
import React from "react";
import BackgroundImage from 'gatsby-background-image';
import * as styles from "./thirsty-section.module.scss"
import { RestaurantId } from "../../components/layout/layout";

export default function ThirstySection() {

    const data = useStaticQuery(graphql`{
    thirstyImage: file(relativePath: { eq: "beefteka/thirsty_background.png" }) {
          childImageSharp {
            fluid(quality: 99) {
              ...GatsbyImageSharpFluid
            }
          }
        }
    }
`)

    return <>
        <span id={RestaurantId}/>
        <div className={styles.container}>
            <BackgroundImage
                className={styles.image}
                fluid={data.thirstyImage.childImageSharp.fluid}
            >
                <div className={styles.textContainer}>
                    <div className={styles.title}>SPRAGNIONY?</div>
                    <div className={styles.subTitle}>
                        Masz ochotę spróbować? Wszystkie nasze piwa
                        <br/>
                        można zakupić w restauracji Beefteka w Kole.
                    </div>
                    <Link
                        className={styles.reference}
                        to={"https://beefteka.pl"}>ODWIEDŹ RESTAURACJĘ
                    </Link>
                    <div className={styles.referenceUnderline}/>
                </div>
            </BackgroundImage>
        </div>
    </>;
}
