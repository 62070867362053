import React from "react"
import * as styles from "./beer-gallery.module.scss"
import { Row, Col } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import NeonFrameRight from "../../svg/neon-frame-right.svg";
import BeerNeonLeft from "../../svg/beer-neon-left.svg";

export default function BeerGalleryItemRight(props) {
  return <Row className={`${styles.container} no-gutters align-items-center`}>
    <Col md={{ span: 6 }} xs={{ span: 12 }}>
      <GatsbyImage
        image={props.image}
        alt={props.header}
        objectFit="cover" />
      {/*<div className={styles.shadowContainer}>*/}
      {/*  <div className={styles.shadow} />*/}
      {/*  <div className={styles.shadowText}>*/}
      {/*    Dobre nadejdzie już wkrótce*/}
      {/*  </div>*/}
      {/*</div>*/}
      <BeerNeonLeft className={`${styles.neon} ${styles.neonLeft}`} />
    </Col>
    <Col md={{ span: 6 }} xs={{ span: 12 }}>
        <Row className={styles.descriptionContainer}>
          <NeonFrameRight className={styles.frame} />
          <Col className={styles.description}>
                <div className={styles.header}>{props.header}</div>
                {props.paragraphs.map(p => <p className={styles.text}>{p}</p>)}
          </Col>
        </Row>
    </Col>
  </Row>;
}
