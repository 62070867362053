import React from "react"
import * as styles from "./beers-section.module.scss"
import { useStaticQuery, graphql } from "gatsby";
import VerticalSpacingRow from "../../components/vertical-spacing/vertical-spacing";
import VerticalSpacingSmallRow from "../../components/vertical-spacing-small/vertical-spacing-small";
import CentralHeaderRow from "../../components/central-header/central-header";
import { OurBeersId } from "../../components/layout/layout";
import { Row } from "react-bootstrap";
import BeerGalleryItemRight from "../../components/beer-gallery/beer-gallery-item-right";
import BeerGalleryItemLeft from "../../components/beer-gallery/beer-gallery-item-left";

export default function BeersSection() {

  const data = useStaticQuery(graphql`{
    beerApi {
        allBeers {
            id,
            name,
            position,
            description,
            imageUrl,
            localImage {
                childImageSharp {
                    gatsbyImageData(quality: 99, layout: FULL_WIDTH)
                }                    
            }
        }
    }
}`);

  const allBeers = data.beerApi.allBeers.sort((a, b) => a.position - b.position);

  return <>
    <div className={styles.wrapper}>
    <span id={OurBeersId} className={styles.idIndicator}></span>
      <VerticalSpacingRow />
      <CentralHeaderRow
        header="NASZE PIWA"
      ></CentralHeaderRow>
      <VerticalSpacingSmallRow />
      <div className="no-gutters align-items-center">
        {allBeers.map((b, i) => {

          if (i % 2 === 0) {
            return <BeerGalleryItemRight
              key={b.id}
              image={b.localImage.childImageSharp.gatsbyImageData}
              header={b.name}
              paragraphs={b.description.split("\n")}
            />;
          }

          return <BeerGalleryItemLeft
            key={b.id}
            image={b.localImage.childImageSharp.gatsbyImageData}
            header={b.name}
            paragraphs={b.description.split("\n")}
          />;

        })}
      </div>
    </div>

  </>;
}
