import React from "react"
import * as styles from "./beer-gallery.module.scss"
import { Row, Col } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import NeonFrameLeft from "../../svg/neon-frame-left.svg";
import BeerNeonRight from "../../svg/beer-neon-right.svg";

export default function BeerGalleryItemLeft(props) {
  return <Row className={`${styles.container} no-gutters align-items-center`}>
    <Col md={{ span: 6, order: 1 }} xs={{ span: 12, order: 2 }}>
        <Row className={styles.descriptionContainer}>
          <NeonFrameLeft className={`${styles.frame} ${styles.frameRight}`} />
          <Col className={`${styles.description} ${styles.descriptionLeft}`}>
                <div className={styles.header}>{props.header}</div>
                {props.paragraphs.map(p => <p className={styles.text}>{p}</p>)}
          </Col>
        </Row>
    </Col>
    <Col md={{ span: 6, order: 2 }} xs={{ span: 12, order: 1 }}>
      <GatsbyImage
        image={props.image}
        alt={props.header}
        objectFit="cover"/>
      {/*<div className={styles.shadowContainer}>*/}
      {/*  <div className={styles.shadow} />*/}
      {/*  <div className={styles.shadowText}>*/}
      {/*    Dobre nadejdzie już wkrótce*/}
      {/*  </div>*/}
      {/*</div>*/}
      <BeerNeonRight className={`${styles.neon} ${styles.neonRight}`} />
    </Col>
  </Row>;
}
