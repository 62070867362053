import React from "react"
import * as styles from "./we-are-section.module.scss"
import { Container, Row, Col } from "react-bootstrap";
import VerticalSpacingRow from "../../components/vertical-spacing/vertical-spacing";
import VerticalLineRow from "../../components/vertical-line/vertical-line";
import LeftHeaderTextRow from "../../components/left-header-text/left-header-text";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import { MeetUsId } from "../../components/layout/layout";

export default function WeAreSection(props) {

  const data = useStaticQuery(graphql`{
    imageDesktop: file(relativePath: {eq: "beefteka/passion.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    },
    imageMobile: file(relativePath: {eq: "beefteka/passion_mobile.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
  }
  `);

  const images = withArtDirection(getImage(data.imageDesktop), [
    {
      media: "(max-width: 767px)",
      image: getImage(data.imageMobile),
    },
  ])

  return <>
    <Container fluid>
      <span id={MeetUsId} className={styles.idIndicator}></span>
      <VerticalSpacingRow />
      <Row className="no-gutters align-items-center">
        <Col md={{ span: 5, offset: 1, order: 1 }} xs={{ span: 12, order: 2 }} className={styles.photoColumn}>
          <GatsbyImage
            image={images}
            alt={"Nalewaki do piwa"}
            objectFit="contain"
            className={styles.photoWrapper} />
        </Col>
        <Col lg={{ span: 5, order: 2 }} md={{ span: 6, order: 2 }} xs={{ span: 12, order: 1 }}>
          <Container fluid>
            {/* todo - check horizontal gutter spacing */}
            <Row>
              <Col xs={12}>
                <LeftHeaderTextRow
                  header={"Z PASJI DO PIWA"}
                  text={`
                  To pasja wyzwala w człowieku najlepsze cechy, dlatego nasze piwa są tworzone z niezwykłą dbałością. Do produkcji używamy naturalnych składników: słodów, drożdży, wody i chmielu. Smak piwa to kwestia gustu, a o nim się nie dyskutuje, dlatego w naszej ofercie znajdziecie piwa klasyczne jak Pilsner czy Weizen, ale nie zabraknie też nowofalowych odmian, przeróżnych IPA z dodatkami owoców i chmielu z całego świata. Każdy znajdzie coś dla siebie.
Mimo tego, że jesteśmy nowym browarem, nasi piwowarzy z wieloletnim doświadczeniem zadbają o to, aby zaspokoić najbardziej wysublimowane gusta. Zapraszamy już dziś! Zobaczcie, jak powstaje piwo! 
                  `}
                />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>



      <VerticalSpacingRow />
      {/* <VerticalLineRow /> */}
    </Container>
  </>;


}