import React from "react"
import { useStaticQuery, graphql } from "gatsby";
import LandingCarousel from "../../components/landing-carousel-slick/landing-carousel";
import LandingCarouselItem from "../../components/landing-carousel-slick/landing-carousel-item";
import * as styles from './landing-section.module.scss';
import FStyleNeon from "../../svg/f-style-neon.svg";
import LogotypeSVG from "../../svg/browar_kolo_logo.svg";

export default function LandingSection(props) {

  const data = useStaticQuery(graphql`{
  image1: file(relativePath: {eq: "beefteka/hero.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 99, layout: FULL_WIDTH)
    }
  }
}
`)

  return <>
    <span id="landing-section"></span>
    <div className={styles.landingCarouselWrapper}>

      <div className={styles.leftNeonWrapper}>
        <FStyleNeon />
      </div>
      <div className={styles.rightNeonWrapper}>
        <FStyleNeon />
      </div>
      <div className={styles.centralLogoWrapper}>
        <LogotypeSVG />
      </div>

      <LandingCarousel>
        <LandingCarouselItem
          imgFluid={data.image1.childImageSharp.gatsbyImageData}
          imgAlt={"browar"}
        />
      </LandingCarousel>
    </div>
  </>;
}
