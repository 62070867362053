import React from "react"
import * as styles from "./gallery-section.module.scss"
import { useStaticQuery, graphql } from "gatsby";
import ImageGallery from "../../components/image-gallery/image-gallery";
import ImageGalleryItem from "../../components/image-gallery/image-gallery-item";
import VerticalSpacingRow from "../../components/vertical-spacing/vertical-spacing";
import VerticalSpacingSmallRow from "../../components/vertical-spacing-small/vertical-spacing-small";
import CentralHeaderRow from "../../components/central-header/central-header";
import additionalData from "./additionalData";

export default function GallerySection() {

    const data = useStaticQuery(graphql`{
      allFile(
        filter: {relativeDirectory: {eq: "beefteka/gallery-images"}}
        sort: {fields: [name], order: DESC}
        limit: 6
      ) {
          edges {
            node {
            name
            childImageSharp {
              gatsbyImageData(width: 720, quality: 99, layout: FULL_WIDTH, aspectRatio: 1)
              }
            }
          }
        }
    }`);

     
    return <>
      <span id="gallery-section"></span>
      <div className={styles.wrapper}>
  
        <VerticalSpacingRow />
        <CentralHeaderRow
          header="POZNAJ NAS BLIŻEJ"
        ></CentralHeaderRow>
        <VerticalSpacingSmallRow />
  
        <ImageGallery>
          {data.allFile.edges.map((edge, index) => {
            const fileData = additionalData[index];
               return (
                  <ImageGalleryItem
                    imgFluid={edge.node.childImageSharp.gatsbyImageData}
                    imgAlt={edge.node.name}
                    key={index}
                    href={fileData.href}
                    textHeader={fileData.tags}
                  />
               );
             })}
      </ImageGallery>
    </div>

  </>;
}
